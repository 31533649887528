import React, {useMemo} from 'react';
import Table from "./Table";
import {prettyFloat, prettyInt} from '../../utils/summary';

const DataSummaryTable = ({itemType, initialData, currentData, project, options, updatedWidth}) => {
    const summaryTableRef = React.useRef(null);

    React.useEffect(() => {
        const handleResize = () => {
            if (summaryTableRef.current) {
                updatedWidth(summaryTableRef.current.offsetWidth);
            }
        };

        window.addEventListener('change', handleResize);

        return () => {
            window.removeEventListener('change', handleResize);
        };
    }, [summaryTableRef])

    const renderLink = (text, path) => (
        <a href={path} target="_blank" rel="noopener noreferrer">{text}</a>
    );

    const totals = initialData[itemType]['raw_input']['totals']

    const shownSections = useMemo(
        () => options.reduce((acc, option) => {
            acc[option.name] = option.checked || false;
            return acc;
        }, {}),
        [options]
    );

    const titleColSpan = (shownSections['initial'] + shownSections['current'])
    
    const Statuses = () => {
        const statuses = {
            'All': {
                classname: 'all',
                enabled: shownSections['all']
            },
            'Active': {
                classname: 'act',
                enabled: shownSections['act']
            },
            'Abandoned': {
                classname: 'abn',
                enabled: shownSections['abn']
            },
        }

        const colSpanCalculator = () => {
            return (
                (shownSections['count'] +
                    shownSections['percent'] +
                    shownSections['length'] +
                    (shownSections['length'] && shownSections['percent']))
                * (shownSections['initial'] + shownSections['current'])
            )
        }


        return Object.keys(statuses).map((status) => {
            const {classname, enabled} = statuses[status]

            if (!enabled) {
                return null
            }

            return (
                <th className={`left separator ${classname} c_1_3`} scope="col" colSpan={colSpanCalculator()}>
                    {status}
                </th>
            )
        })
    }

    // This is used to prompt a run diagnostics
    // since the projects that were in the old
    // db, didn't have the incoherent_filtered field in the stats
    if (!initialData[itemType]['incoherent_filtered']) {
        return (<button
            onClick={() => {
                Api.post(`projects/${project.id}/request-diagnostics`, {})
                    .then(() => {

                        window.location.reload();

                    })
            }}
            className="btn btn-info">
            Please Run Diagnostics
        </button>)
    }

    return (
        <div>
            <table ref={summaryTableRef} className={`adjusted data-summary ${itemType}`}>
                <thead>
                <tr>
                    <td rowSpan="2" className="empty">
                        {itemType === 'pipes' ? 'Pipes' : 'Breaks'}
                    </td>
                    <Statuses/>
                </tr>
                <tr className="titles">
                    {shownSections['all'] && shownSections['count'] &&
                        <th colSpan={titleColSpan} scope="col"
                            className="left separator count all value left separator c_1_6">
                            Number
                        </th>
                    }
                    {shownSections['all'] && shownSections['percent'] &&
                        <th colSpan={titleColSpan} scope="col" className="count all percent c_1_6">
                            %
                        </th>
                    }
                    {shownSections['all'] && shownSections['length'] &&
                        <th colSpan={titleColSpan} scope="col" className="count all length c_1_6">
                            Length
                        </th>
                    }
                    {shownSections['all'] && shownSections['percent'] && shownSections['length'] &&
                        <th colSpan={titleColSpan} scope="col" className="count all percent c_1_6">
                            %
                        </th>
                    }
                    {shownSections['act'] && shownSections['count'] &&
                        <th colSpan={titleColSpan} scope="col" className="left separator count act value c_1_6">
                            Number
                        </th>
                    }
                    {shownSections['act'] && shownSections['percent'] &&
                        <th colSpan={titleColSpan} scope="col" className="count act percent c_1_6">
                            %
                        </th>
                    }
                    {shownSections['act'] && shownSections['length'] &&
                        <th colSpan={titleColSpan} scope="col" className="count act length c_1_6">
                            Length
                        </th>
                    }
                    {shownSections['act'] && shownSections['percent'] && shownSections['length'] &&
                        <th colSpan={titleColSpan} scope="col" className="count act percent c_1_6">
                            %
                        </th>
                    }
                    {shownSections['abn'] && shownSections['count'] &&
                        <th colSpan={titleColSpan} scope="col" className="left separator count abn value c_1_6">
                            Number
                        </th>
                    }
                    {shownSections['abn'] && shownSections['percent'] &&
                        <th colSpan={titleColSpan} scope="col" className="count abn percent c_1_6">
                            %
                        </th>
                    }
                    {shownSections['abn'] && shownSections['length'] &&
                        <th colSpan={titleColSpan} scope="col" className="count abn length c_1_6">
                            Length
                        </th>
                    }
                    {shownSections['abn'] && shownSections['percent'] && shownSections['length'] &&
                        <th colSpan={titleColSpan} scope="col" className="count abn percent c_1_6">
                            %
                        </th>
                    }
                </tr>
                </thead>
                <tbody>
                <tr className="input-file totals titles">
                    <th rowSpan="2" scope="row" className="label">From input file</th>
                    <React.Fragment>
                        {['all', 'act', 'abn'].map((status) => {
                                if (!shownSections[status]) {
                                    return null
                                }

                                let percentageCount = (totals[status]['count'] * 100.0) / totals[(['act', 'abn'].includes(status) ? 'all' : status)]['count']
                                let percentageLength = (totals[status]['length'] * 100.0) / totals[(['act', 'abn'].includes(status) ? 'all' : status)]['length']

                                return (
                                    <React.Fragment>
                                        {shownSections['count'] &&
                                            <td
                                                title={`Number of ${itemType} at the beginning of the project`}
                                                colSpan={titleColSpan}
                                                className={`left separator count ${status} value ${itemType === 'breaks' ? ' left separator' : ''} c_1_6`}
                                            >
                                                {renderLink(prettyInt(totals[status].count), `/projects/${project.id}/${itemType}?scope=all&initial=1&sort=updated_at&dir=desc${shownSections.project_stats ? '&excludeFilteredData=true' : ''}`)}
                                            </td>
                                        }
                                        {shownSections['percent'] &&
                                            <td colSpan={titleColSpan} className="count all percent c_1_6">
                                                <span className="hundred-percent">{
                                                    prettyFloat(percentageCount)
                                                }%</span>
                                            </td>
                                        }
                                        {shownSections['length'] &&
                                            <td colSpan={titleColSpan}
                                                title={`Total length of ${itemType} at the beginning of the project`}
                                                className="count all length c_1_6">
                                                {prettyFloat(totals[status].length / 5280.0)}
                                            </td>
                                        }
                                        {shownSections['percent'] && shownSections['length'] &&
                                            <td colSpan={titleColSpan} className="count all percent c_1_6">
                                                <span className="hundred-percent">{
                                                    prettyFloat(percentageLength)
                                                }%
                                                    </span>
                                            </td>
                                        }
                                    </React.Fragment>
                                )
                            }
                        )}
                    </React.Fragment>
                </tr>
                <tr className="initial_current_titles">
                    {['all', 'act', 'abn'].map((status) => {
                        if (!shownSections[status]) {
                            return null
                        }

                        return (
                            <React.Fragment>
                                {shownSections['count'] &&
                                    <React.Fragment>
                                        {shownSections.initial &&
                                            <th className={`left separator ${status} value count initial`}>
                                                <small>initial</small>
                                            </th>
                                        }
                                        {shownSections.current &&
                                            <th className={`${status} value count current`}>
                                                <small>current</small>
                                            </th>
                                        }
                                    </React.Fragment>
                                }
                                {shownSections['percent'] &&
                                    <React.Fragment>
                                        {shownSections.initial &&
                                            <th className={`${status} percent count initial`}>
                                                <small>initial</small>
                                            </th>
                                        }
                                        {shownSections.current &&
                                            <th className={`${status} percent count current`}>
                                                <small>current</small>
                                            </th>
                                        }
                                    </React.Fragment>
                                }
                                {shownSections['length'] &&
                                    <React.Fragment>
                                        {shownSections.initial &&
                                            <th className={`left separator ${status} length count initial`}>
                                                <small>initial</small>
                                            </th>
                                        }
                                        {shownSections.current &&
                                            <th className={`${status} length count current`}>
                                                <small>current</small>
                                            </th>
                                        }
                                    </React.Fragment>
                                }
                                {shownSections['percent'] && shownSections['length'] &&
                                    <React.Fragment>
                                        {shownSections.initial &&
                                            <th className={`${status} percent count initial`}>
                                                <small>initial</small>
                                            </th>
                                        }
                                        {shownSections.current &&
                                            <th className={`${status} percent count current`}>
                                                <small>current</small>
                                            </th>
                                        }
                                    </React.Fragment>
                                }
                            </React.Fragment>
                        )
                    })}
                </tr>
                {

                    (shownSections.project_stats ? [
                            'filtered',
                            'initial_filtered',
                            'incoherent_filtered',
                            'selected'
                    ] : [
                        'incoherent',
                        'coherent'
                    ]).map((level) =>
                        <Table
                            level={level}
                            itemType={itemType}
                            project={project}
                            currentData={currentData[itemType]}
                            initialData={initialData[itemType]}
                            shownSections={shownSections}
                        />
                    )}
                </tbody>
            </table>
            <style jsx>
                {`
          table.data-summary .current {
            color: blue;
          }
          table.data-summary .initial {
            color: red;
          }
        `}
            </style>
        </div>
    );
};

export default DataSummaryTable;
